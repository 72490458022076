import React, { useState } from "react";
import {
  Button,
  Col,
  Row,
  Select,
  Upload,
  Input,
  Modal
} from 'antd';
import axios from 'axios';

import endpoints from "../../utils/endpoints";
import { DeleteOutlined } from "@ant-design/icons";

function Update({ open, close, data }) {

  const [isButtonLoading, setIsButtonLoading] = useState(false);

  function handleClose() {
    close();
  }

  function handleDelete() {
    setIsButtonLoading(true);
    axios.post(endpoints.voting.delete, {
      data: data
    }).then((res) => {
      setTimeout(() => {
        setIsButtonLoading(false);
        handleClose();
      }, 1000);
    }).catch(error => {
      setIsButtonLoading(false);
      console.error(error);
    });
  };

  return (
    <Modal
      title={`Delete voting`}
      key={`modal-delete-guide-${data.id}`}
      width={800}
      onCancel={handleClose}
      open={open}
      footer={[
        <Button danger icon={<DeleteOutlined />} size="large" type="primary" onClick={handleDelete} loading={isButtonLoading} disabled={isButtonLoading}>
          Delete
        </Button>,
        <Button size="large" onClick={close}>
          Cancel
        </Button>
      ]}
    >
      <Row>
        <Col span={24}>
          <p className="f-16">Are you sure that you wanna delete this voting:</p>
          <p className="f-20 bold mt-20">{data.name}</p>
        </Col>
      </Row>
    </Modal>
  );
};

export default Update;