import React, { useState, useEffect } from "react";
import {
  Select,
  Upload,
  Input,
  Table
} from 'antd';
import 'react-quill/dist/quill.snow.css';

function ExportData({ data, columns }) {

  const [isButtonLoading, setIsButtonLoading] = useState(false);

  useEffect(() => {
    console.log(data);
  }, [data]);

  return (
    <div className="d-flex flex-column jc-center ai-center p-20">
      <p className="f-20 mt-10 mb-5">Vão ser exportados:</p>
      <p className="bold f-40 mt-0 mb-0">{data.length}</p>
      <p className="f-20 mt-0 mb-30">utilizadores</p>
      <Table
        columns={columns}
        dataSource={data}
        scroll={{ x: 1 }}
      />
    </div>
  );
};

export default ExportData;