import {
  Row,
  Col,
  Button,
  Input,
  DatePicker,
  TimePicker,
  Select,
  Radio,
  Form,
  notification,
  Drawer,
  Switch,
  Checkbox
} from "antd";
import { useEffect, useState } from "react";
import axios from 'axios';
import TextArea from "antd/lib/input/TextArea";
import dayjs from "dayjs";
import ReactQuill from "react-quill";

import config from "../../utils/config.js";
import "react-quill/dist/quill.snow.css";
import { DeleteOutlined, PlusCircleFilled, PlusCircleOutlined } from "@ant-design/icons";
import endpoints from "../../utils/endpoints.js";

const { Option } = Select;
const { RangePicker } = TimePicker;

function DrawerAddProgram({ open, close }) {

  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const [form] = Form.useForm();

  function handleSubmit(values) {
    setIsButtonLoading(true);

    axios.post(endpoints.faqs.create, {
      data: values
    }).then((res) => {
      setTimeout(() => {
        close();
        setIsButtonLoading(false);
        form.resetFields();
        notification.success({
          message: 'Yeah!',
          description: 'Program created with success'
        })
      }, 2000)
    }).catch((err) => {
      console.log(err);
      setIsButtonLoading(false);
      notification.error({
        message: 'Oops!',
        description: 'Something wrong happened! Try again later.'
      })
    })
  }

  function handleWarningMissingFields() {
    notification.error({
      message: `Campos por preencher`,
      description: 'Faltam alguns campos para preencher!'
    })
  }

  function handleCloseDrawer() {
    close();
    setIsButtonLoading(false);
    form.resetFields();
  }

  return (
    <Drawer
      width={800}
      title={`Create faq`}
      open={open}
      onClose={handleCloseDrawer}
      maskClosable={false}
      extra={[
        <Button className="mr-10" size="large" type="primary" onClick={form.submit} loading={isButtonLoading} disabled={isButtonLoading}>
          Create
        </Button>,
        <Button size="large" onClick={close}>
          Cancel
        </Button>
      ]}
    >
      <Form
        form={form}
        id="add-program-form"
        layout="vertical"
        onFinish={handleSubmit}
        onFinishFailed={handleWarningMissingFields}
      >
        <Row gutter={[24, 0]}>
          <Col span={24}>
            <Form.Item
              name="title"
              label="Title"
              rules={[{ required: true, message: 'Este é um campo obrigatório' }]}
            >
              <ReactQuill
                placeholder="Title..."
                theme="snow"
              />
            </Form.Item>

            <Form.Item
              name="description"
              label="Description"
            >
              <ReactQuill
                placeholder="Description..."
                theme="snow"
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
}

export default DrawerAddProgram;
