import React, { useState } from "react";
import {
  Button,
  Form,
  Drawer,
  message,
  Input,
  Row,
  Col,
  Select,
  Switch,
} from 'antd';
import axios from 'axios';
import { useForm } from "antd/es/form/Form";
import ReactQuill from "react-quill";

import endpoints from "../../utils/endpoints";
import "react-quill/dist/quill.snow.css";

function Create({ open, close }) {

  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const [form] = useForm();

  function handleClose() {
    form.resetFields();
    setIsButtonLoading(false);
    close();
  }

  function handleSubmit(values) {
    setIsButtonLoading(true);
    axios.post(endpoints.voting.create, {
      data: values
    }).then((res) => {
      setTimeout(() => {
        setIsButtonLoading(false);
        handleClose();
        form.resetFields();
      }, 1000);
    }).catch(error => {
      setIsButtonLoading(false);
      console.error(error);
    });
  };

  return (
    <Drawer
      className="drawer-user"
      key={`drawer-create-vote`}
      title="Create voting"
      width={800}
      onClose={handleClose}
      open={open}
      bodyStyle={{
        paddingBottom: 80,
      }}
      extra={[
        <Button size="large" disabled={isButtonLoading} className="mr-10" onClick={handleClose}>Cancel</Button>,
        <Button size="large" loading={isButtonLoading} type="primary" onClick={form.submit}>
          Create
        </Button>
      ]}
    >
      <Form
        form={form}
        onFinish={handleSubmit}
        layout="vertical"
      >
        <Form.Item
          label="Nome"
          name="name"
          rules={[
            {
              required: true,
              message: 'This field is required'
            }
          ]}
        >
          <Input placeholder="Name..." size="large" />
        </Form.Item>
        <Form.Item
          name="description"
          label="Description"
        >
          <Input placeholder="Description..." size="large" />
        </Form.Item>
        <Form.Item
          label="Link"
          name="link"
          rules={[
            {
              required: true,
              message: 'This field is required'
            }
          ]}
        >
          <Input placeholder="Link..." size="large" />
        </Form.Item>
        <Form.Item
          label="Active"
          name="is_active"
          valuePropName="checked"
        >
          <Switch size="large" />
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default Create;