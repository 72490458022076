import {
  Row,
  Col,
  Divider,
  Tabs,
  Form,
  notification,
  Switch,
  Spin,
} from "antd";
import { useEffect, useState } from "react";
import axios from 'axios';

import config from "../../utils/config.js";
import SettingsVersion from "./SettingsVersion";

import "react-quill/dist/quill.snow.css";
import "./Settings.css";
import endpoints from "../../utils/endpoints.js";
import SettingsWifi from "./SettingsWifi.js";
import SettingsTermsConditions from "./SettingsTermsConditions.js";

function Settings() {

  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [settings, setSettings] = useState([]);
  const [form] = Form.useForm();

  useEffect(() => {
    handleGetDefinitions();
  }, []);

  function handleGetDefinitions() {
    setIsLoading(true);
    axios.get(
      endpoints.settings.read
    ).then((response) => {
      setSettings(response.data);
      setIsLoading(false);
    }).catch((err) => {
      console.log(err);
      setIsLoading(false);
    });
  }

  return (
    <div className='dashboard_page'>
      <Row className='title_row mb-20 dashboard_title_row'>
        <Col span={12} className="d-flex jc-start ai-center">
          <h3 className="mt-0 mb-0">Definições</h3>
        </Col>
      </Row>
      <Divider />
      <Spin spinning={isLoading}>
        <Row className="dashboard_content_row">
          <Col span={24}>
            <Tabs
              type="card"
              items={[{
                label: `Version`,
                key: '1',
                children: <SettingsVersion data={settings} />,
              }, {
                label: `Wi-fi`,
                key: '2',
                children: <SettingsWifi data={settings} />,
              }, {
                label: `Terms and conditions`,
                key: '3',
                children: <SettingsTermsConditions data={settings} />,
              }]}
            />
          </Col>
        </Row>
      </Spin>
    </div>
  );
}

export default Settings;
