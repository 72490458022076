import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Drawer,
  message,
  Input,
  Row,
  Col,
  Select,
  Switch,
  InputNumber,
  Table,
} from 'antd';
import axios from 'axios';
import { useForm } from "antd/es/form/Form";
import ReactQuill from "react-quill";

import endpoints from "../../utils/endpoints";
import DrawerExportTable from '../Export/DrawerExportTable';
import "react-quill/dist/quill.snow.css"
import dayjs from "dayjs";

function Read({ data, open, close }) {

  const [isLoading, setIsLoading] = useState(false);
  const [isOpenExport, setIsOpenExport] = useState(false);
  const [columns, setColumns] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [form] = useForm();

  useEffect(() => {
    if (open) {
      console.log(data);
      handlePrepareData(data);
      form.setFieldsValue(data);
    }
  }, [open]);

  function handlePrepareData(data) {
    let auxColumns = JSON.parse(data[0].answers);
    let auxColumnsTable = [{
      title: 'Name',
      dataIndex: 'name',
      width: 900
    }, {
      title: 'E-mail',
      dataIndex: 'email',
      width: 900
    }];

    for (let i = 0; i < auxColumns.length; i++) {
      auxColumnsTable.push({
        title: auxColumns[i].question,
        dataIndex: `question_${i + 1}`,
        width: 900
      })
    }

    let auxData = [];
    for (let z = 0; z < data.length; z++) {
      let answers = JSON.parse(data[z].answers);
      let auxObject = {
        name: data[z].name,
        email: data[z].email,
      };

      for (let y = 0; y < answers.length; y++) {
        auxObject = { ...auxObject, [`question_${y + 1}`]: answers[y].answer }
      }

      auxData.push(auxObject)
    }


    console.log(auxData);
    console.log(auxColumnsTable);
    setTableData(auxData);
    setColumns(auxColumnsTable);
  }

  function handleOpenExport(){
    setIsOpenExport(true);
  }

  function handleCloseExport() {
    setIsOpenExport(false);
  }

  return (
    <Drawer
      className="drawer-user"
      key={`drawer-create-user`}
      title="Answers"
      width={'90%'}
      onClose={close}
      open={open}
      bodyStyle={{
        paddingBottom: 80,
      }}
      extra={[<Button size="large" onClick={handleOpenExport}>Export</Button>]}
    >
      <DrawerExportTable open={isOpenExport} close={handleCloseExport} data={tableData} />
      <Row>
        <Col span={24}>
          <Table
            loading={isLoading}
            columns={columns}
            dataSource={tableData}
            scroll={{ x: 2500 }}
          />
        </Col>
      </Row>
    </Drawer>
  );
};

export default Read;