import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Button,
  Table,
  Input,
  Form,
  Avatar
} from 'antd';
import { EditOutlined, DeleteOutlined, ReloadOutlined } from '@ant-design/icons';
import axios from "axios";

import Update from "../../components/Faqs/Update";
import Create from "../../components/Faqs/Create";
import Delete from "../../components/Faqs/Delete";
import endpoints from "../../utils/endpoints";

import './OfficialAgency.css'
import config from "../../utils/config";

const OfficialAgency = () => {

  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [isOpenCreate, setIsOpenCreate] = useState(false);
  const [isOpenUpdate, setIsOpenUpdate] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'E-mail',
      dataIndex: 'email',
    },
    {
      title: 'Question',
      dataIndex: 'question',
    },
  ];

  useEffect(() => {
    handleGetData();
  }, []);

  function handleGetData() {
    setIsLoading(true);
    axios.get(endpoints.pco.read)
      .then(res => {
        setData(res.data);
        handlePrepareData(res.data);
      })
      .catch(error => {
        setIsLoading(false);
        console.error(error)
      });
  }

  function handlePrepareData(data) {
    setIsLoading(true);
    let aux = [];
    for (let i = 0; i < data.length; i++) {
      let auxObj = {
        id: data[i].id,
        name: data[i].name,
        email: data[i].email,
        question: data[i].question,
        full_data: data[i]
      }

      aux.push(auxObj);
    }

    setIsLoading(false);
    setTableData(aux);
  }

  function handleOpenCreate() {
    setIsOpenCreate(true);
  }

  function handleCloseCreate() {
    setIsOpenCreate(false);
    handleGetData();
  }

  function handleOpenUpdate(data) {
    setSelectedData(data);
    setIsOpenUpdate(true);
  }

  function handleCloseUpdate() {
    setIsOpenUpdate(false);
    handleGetData();
  }

  function handleOpenDelete(data) {
    setSelectedData(data);
    setIsOpenDelete(true);
  }

  function handleCloseDelete() {
    setSelectedData({});
    setIsOpenDelete(false);
    handleGetData();
  }

  return (
    <div className='dashboard_page'>
      <Row>
        <Create open={isOpenCreate} close={handleCloseCreate} />
        <Update open={isOpenUpdate} close={handleCloseUpdate} data={selectedData} />
        <Delete open={isOpenDelete} close={handleCloseDelete} data={selectedData} />
        <Col span={24}>

          <Row className='title_row mb-20 dashboard_title_row'>
            <Col span={6}>
              <h3 className="mt-0 mb-0">Official Agency</h3>
            </Col>
            <Col span={18}>
              <div className="d-flex jc-end ai-center">
                <Button className="mr-10" size="large" onClick={handleGetData}><ReloadOutlined /></Button>
              </div>
            </Col>
          </Row>
          <Row className="dashboard_content_row">
            <Col span={24}>
              <Table
                loading={isLoading}
                columns={columns}
                dataSource={tableData}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default OfficialAgency;