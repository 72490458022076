import {
  Row,
  Col,
  Button,
  Input,
  DatePicker,
  TimePicker,
  Select,
  Radio,
  Form,
  notification,
  Drawer,
  Switch,
  Checkbox
} from "antd";
import { useEffect, useState } from "react";
import axios from 'axios';
import TextArea from "antd/lib/input/TextArea";
import dayjs from "dayjs";
import ReactQuill from "react-quill";

import config from "../../utils/config.js";
import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";

import "react-quill/dist/quill.snow.css";
import endpoints from "../../utils/endpoints.js";

const { Option } = Select;
const { RangePicker } = TimePicker;

function Update({ data, open, close, optionsType, moderators, speakers }) {

  const [moderatorsData, setModeratorsData] = useState([]);
  const [speakersData, setSpeakersData] = useState([]);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isOpenAddModerator, setIsOpenAddModerator] = useState(false);
  const [isOpenAddSpeaker, setIsOpenAddSpeaker] = useState(false);
  const [locations, setLocations] = useState([]);

  const [form] = Form.useForm();

  useEffect(() => {
    if (Object.keys(data).length > 0 && open) {
      console.log(data);
      form.setFieldsValue(data);
    }
  }, [data]);

  function handleSubmit(values) {
    setIsButtonLoading(true);

    axios.post(endpoints.faqs.update, {
      data: values
    }).then((response) => {
      setTimeout(() => {
        close();
        setIsButtonLoading(false);
        form.resetFields();
      }, 2000);
    }).catch((err) => {
      console.log(err);
      setIsButtonLoading(false);
    })
  }

  function handleWarningMissingFields() {
    notification.error({
      message: `Campos por preencher`,
      description: 'Faltam alguns campos para preencher!'
    })
  }

  function handleCloseDrawer() {
    close();
    setIsButtonLoading(false);
    form.resetFields();
  }

  return (
    <Drawer
      width={800}
      title={`Update faq`}
      open={open}
      onClose={handleCloseDrawer}
      maskClosable={false}
      extra={[
        <Button className="mr-10" size="large" type="primary" onClick={form.submit} loading={isButtonLoading} disabled={isButtonLoading}>
          Update
        </Button>,
        <Button size="large" onClick={handleCloseDrawer}>
          Cancel
        </Button>
      ]}
    >
      <Form
        form={form}
        id="add-program-form"
        layout="vertical"
        onFinish={handleSubmit}
        onFinishFailed={handleWarningMissingFields}
      >
        <Row gutter={[24, 0]}>
          <Col span={24}>
            <Form.Item
              name="id"
              hidden
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="title"
              label="Title"
              rules={[{ required: true, message: 'Este é um campo obrigatório' }]}
            >
              <ReactQuill
                key="description"
                theme="snow"
              />
            </Form.Item>
            <Form.Item
              name="description"
              label="Description"
            >
              <ReactQuill
                key="description"
                theme="snow"
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
}

export default Update;
